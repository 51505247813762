<template>
  <div
    :class="{
      'common-container': true,
      'meet-room': 'meetRoom' === meetDetails.room,
      'full-height': isHeaderFooterHidden
    }"
    v-on:dblclick="toggleFullScreen"
  >
    <!-- Waiting Room -->
    <div class="waiting-room" v-if="'waitRoom' === meetDetails.room">
      <!-- Overlay box -->
      <overlay-box-vue
        :is-chat-pannel-opened="isChatPannelOpened"
        :toggle-sidebar="toggleSidebar"
      ></overlay-box-vue>

      <!-- Waiting room animation video -->
      <div class="wr-graphic" ref="screenContainer">
        <video autoplay muted loop>
          <source :src="waitRoomvideoFileName" type="video/mp4" />
          <img
            :src="waitingRoomImg"
            title="Your browser does not support the <video> tag"
          />
        </video>
      </div>
    </div>

    <!-- Meeting Room -->
    <div
      id="video-room"
      v-show="'meetRoom' === meetDetails.room"
      :class="{ 'room-pinned': 'pinned' === displayView }"
    >
      <!-- Listing participants -->
      <participant-list-vue
        :toggle-rename-popup="toggleRenamePopup"
        :hide-self-view="hideSelfView"
        v-show="Object.keys(peerConnections).length > 0"
      ></participant-list-vue>

      <!-- Video Thumbnail -->
      <video-thumb-vue
        :toggle-rename-popup="toggleRenamePopup"
        :hide-self-view="hideSelfView"
        v-show="
          0 === Object.keys(peerConnections).length ||
            ('speaker' === displayView && !meetDetails.hideSelfView) ||
            'pinned' === displayView
        "
      ></video-thumb-vue>
    </div>

    <!-- Record indicator on paticipant side -->
    <span
      class="record-indicator"
      v-if="hostInfo.id !== userId && meetDetails.recordStatus"
    >
      Recording in progress
    </span>

    <!-- Chat Notifications -->
    <div class="chat-notification">
      <transition-group name="slide" tag="div">
        <chat-notification-vue
          v-for="notification in notifications"
          :notification="notification"
          :is-chat-pannel-opened="isChatPannelOpened"
          :toggle-sidebar="toggleSidebar"
          :key="notification.id"
        ></chat-notification-vue>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import OverlayBoxVue from "./OverlayBox";
import ParticipantListVue from "./ParticipantList";
import VideoThumbVue from "./VideoThumb";
import ChatNotificationVue from "./ChatNotification";
import { participant } from "../../mixins/Participant";

export default {
  name: "Section",
  components: {
    OverlayBoxVue,
    VideoThumbVue,
    ChatNotificationVue,
    ParticipantListVue
  },
  mixins: [participant],
  props: {
    toggleSidebar: Function,
    toggleFullScreen: Function,
    isChatPannelOpened: Boolean
  },
  data() {
    return {
      waitingRoomImg: require("../../assets/img/waiting-room.jpg"),
      firstVideo: require("../../assets/video/waiting-room-3.mp4"),
      secondVideo: require("../../assets/video/waiting-room-6.mp4"),
      thirdVideo: require("../../assets/video/waiting-room-9.mp4"),
      fourthVideo: require("../../assets/video/waiting-room-12.mp4")
    };
  },
  computed: {
    ...mapGetters({
      isHeaderFooterHidden: "getIsHeaderFooterHidden",
      userId: "getUserId"
    }),

    ...mapGetters("chat", {
      messages: "getMessages",
      notifications: "getNotifications"
    }),

    ...mapGetters("meet", {
      hostInfo: "getHostInfo",
      peerConnections: "getPeerConnections",
      meetDetails: "getMeetDetails",
      displayView: "getDisplayView"
    }),

    /**
     * Returns the wait-room video file name based on current month
     */
    waitRoomvideoFileName() {
      let month = new Date().getMonth();
      let fileName = "";
      if (month >= 2 && month <= 4) {
        fileName = this.firstVideo;
      } else if (month >= 5 && month <= 7) {
        fileName = this.secondVideo;
      } else if (month >= 8 && month <= 10) {
        fileName = this.thirdVideo;
      } else {
        fileName = this.fourthVideo;
      }

      return fileName;
    }
  },
  watch: {
    isChatPannelOpened: function(value) {
      if (value) {
        this.setNotifications([]);
      }
    }
  },
  methods: {
    ...mapMutations({
      setModal: "setModal"
    }),

    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    ...mapMutations({
      setDisplayModalOverlay: "setDisplayModalOverlay"
    }),

    ...mapMutations("chat", {
      setNotifications: "setNotifications"
    }),

    /**
     * Function to toggle rename pop-up
     */
    toggleRenamePopup() {
      this.setModal({
        ...this.modal,
        display: true,
        title: "Rename",
        bodyComponentName: "rename-vue",
        styleOverride: {}
      });
      this.setDisplayModalOverlay(true);
    },

    /**
     * Function to hide self view of loggedin user
     */
    hideSelfView() {
      if (Object.keys(this.peerConnections).length > 0) {
        this.setMeetDetails({
          ...this.meetDetails,
          hideSelfView: true
        });
      }
    }
  }
};
</script>

<style scoped>
/* Hiding the controls of videos */
.flipEffect {
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
}
.flipEffect::-webkit-media-controls {
  display: none !important;
}
.common-container.meet-room {
  height: calc(100% - 60px);
  position: relative;
}
video {
  min-width: 100%;
  max-width: 100%;
}
#video-room {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
}
.slide-enter {
  /* margin-left: -200px; */
  margin-bottom: -50px;
  opacity: 0;
}
.slide-enter-to {
  /* margin-left: 0px; */
  margin-bottom: 10px;
  opacity: 1;
}
.slide-leave {
  margin-bottom: 30px;
  opacity: 1;
}
.slide-leave-to {
  margin-bottom: 30px;
  opacity: 0;
}
.chat-notification {
  position: absolute;
  bottom: 60px;
  display: flex;
  flex-direction: column;
}
.meet-room .chat-notification {
  bottom: 0px;
}
.record-indicator {
  color: red;
  position: absolute;
  top: 5px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  img.mb-img {
    display: none;
  }

  #video-room {
    overflow: auto;
  }
}
@media (max-width: 768px) {
  .wr-graphic video {
    display: none;
  }

  .wr-graphic {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../../assets/img/waiting-room-mobile.png) center center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  #video-room.room-pinned {
    flex-direction: column-reverse;
  }
}
@media screen and (orientation: landscape) and (max-height: 425px) {
  #video-room.room-pinned {
    flex-direction: row;
  }
}
</style>
