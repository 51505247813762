import socket from "../../socket/socket";
import { mapGetters, mapMutations, mapActions } from "vuex";

export const peerConnectionEventListener = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      peerConnections: "getPeerConnections"
    }),

    ...mapGetters({
      userId: "getUserId",
      userName: "getUserName"
    })
  },
  methods: {
    ...mapMutations("meet", {
      pushParticipantToMeetingRoom: "pushParticipantToMeetingRoom",
      removeParticipantFromWaitingRoom: "removeParticipantFromWaitingRoom",
      setJoinId: "setJoinId"
    }),

    ...mapActions("meet", {
      updateSocketLeaveTimePeriodically: "updateSocketLeaveTimePeriodically"
    }),

    /**
     * Listening to socket events for creating peer connection
     */
    listenToPeerConnectionEvent() {
      // Listener get executed to notify the participant to start the peer connection
      socket.io.on("startWebRtcConnection", data => {
        this.setMeetDetails({
          ...this.meetDetails,
          enableChat: data.enableChat,
          screenShare: data.screenShare,
          enableUnmute: data.enableUnmute
        });

        socket.io.emit("newConnection", {
          socketId: socket.io.id,
          userId: this.userId,
          userName: this.userName,
          isAudioMuted: this.meetDetails.isUserAudioMuted,
          isVideoMuted: this.meetDetails.isUserVideoMuted,
          videoTrackType: this.meetDetails.videoTrackType
        });

        // Update socket leaving time periodically
        this.updateSocketLeaveTimePeriodically();

        // Setting join id on vuex
        this.setJoinId(data.meetJoinInfoId);
      });

      // Listener to create peer connection for the new participant
      socket.io.on("newConnection", data => {
        // Set up peer connection object for a new peer
        if (data.socketId !== socket.io.id) {
          this.setUpPeer(data);

          // Emit to destination socket that new connection is created.
          console.log("newConnection fired");
          console.log(data);
          socket.io.emit("newConnectionSuccess", {
            socketId: socket.io.id,
            dest: data.socketId,
            userId: this.userId,
            userName: this.userName,
            isAudioMuted: this.meetDetails.isUserAudioMuted,
            isVideoMuted: this.meetDetails.isUserVideoMuted,
            videoTrackType: this.meetDetails.videoTrackType
          });
        }

        // Removing user socket connection from waiting room list
        this.removeParticipantFromWaitingRoom(data.socketId);

        // Push user socket connection to meeting room list
        this.pushParticipantToMeetingRoom(data);
      });

      // Create new peer connection,
      // when new connection is successfully created on other side.
      socket.io.on("newConnectionSuccess", data => {
        console.log("newConnectionSuccess fired");
        console.log(data);
        this.setUpPeer(data, true);
      });

      // Listener to be called when creating an offer or answer
      socket.io.on("description", data => {
        this.peerConnections[data.id].pc
          .setRemoteDescription(new RTCSessionDescription(data.description))
          .then(() => {
            // Only create answers in response to offers
            if ("offer" === data.description.type) {
              this.peerConnections[data.id].pc
                .createAnswer()
                .then(description =>
                  this.createdDescription(description, data.id)
                )
                .catch(this.showErrOnConsole);
            }
          })
          .catch(this.showErrOnConsole);
      });

      // Listener to be fired when peers share ice information with each other.
      socket.io.on("ice", data => {
        this.peerConnections[data.id].pc
          .addIceCandidate(new RTCIceCandidate(data.ice))
          .catch(this.showErrOnConsole);
      });

      socket.io.on("reconnect", this.reconnect);
    },

    /**
     * Functions for reconnecting a peer connection
     *
     * @param {Object} data
     */
    reconnect(data) {
      if (this.peerConnections[data.socketId]) {
        console.log("timer Running");
        setTimeout(this.reconnect, 500, data);
      } else {
        console.log("Starting new socket connection");
        socket.io.emit("newConnection", {
          dest: data.socketId,
          socketId: socket.io.id,
          userId: this.userId,
          userName: this.userName,
          isAudioMuted: this.meetDetails.isUserAudioMuted,
          isVideoMuted: this.meetDetails.isUserVideoMuted,
          videoTrackType: this.meetDetails.videoTrackType
        });
      }
    },

    /**
     * Handling errors
     */
    showErrOnConsole(error) {
      console.log(error);
    }
  }
};
