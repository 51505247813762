import { mapMutations, mapGetters, mapActions } from "vuex";
import socket from "../socket/socket";
import { checkMobile } from "./CheckMobile";

export const device = {
  data() {
    return {};
  },
  mixins: [checkMobile],
  computed: {
    ...mapGetters({
      userName: "getUserName",
      userId: "getUserId"
    }),

    ...mapGetters("meet", {
      videoInput: "getVideoInput",
      audioInput: "getAudioInput",
      audioOutput: "getAudioOutput",
      hostInfo: "getHostInfo",
      rtcRtpSenders: "getRtcRtpSenders",
      meetDetails: "getMeetDetails",
      cameraStream: "getCameraStream",
      cameraFacingMode: "getCameraFacingMode",
      isCameraStreamReady: "getIsCameraStreamReady",
      audioTrack: "getAudioTrack",
      videoTrack: "getVideoTrack"
    })
  },
  watch: {
    /**
     * Watchers to detect for camera facing mode change
     */
    cameraFacingMode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.changeCameraFacingMode();
      }
    }
  },
  methods: {
    ...mapMutations("meet", {
      setAudioInput: "setAudioInput",
      setVideoInput: "setVideoInput",
      setAudioOutput: "setAudioOutput",
      setIsCameraStreamReady: "setIsCameraStreamReady",
      setCameraStream: "setCameraStream",
      setSelectedAudioInput: "setSelectedAudioInput",
      setSelectedVideoInput: "setSelectedVideoInput",
      setSelectedAudioOutput: "setSelectedAudioOutput",
      setVideoTrack: "setVideoTrack",
      setAudioTrack: "setAudioTrack",
      setMeetDetails: "setMeetDetails",
      setParticipantList: "setParticipantList",
      setJoinId: "setJoinId"
    }),

    ...mapActions({
      showHeaderFooter: "showHeaderFooter"
    }),

    ...mapActions("meet", {
      updateSocketLeaveTimePeriodically: "updateSocketLeaveTimePeriodically"
    }),

    /**
     * Function to add event listener for footer toggling
     */
    addToggleHeaderFooterEventListener() {
      window.addEventListener("click", event => {
        this.showHeaderFooter({ runTimeOut: true });
      });
      window.addEventListener("mousemove", event => {
        this.showHeaderFooter({ runTimeOut: true });
      });
      window.addEventListener("mousedown", event => {
        this.showHeaderFooter({ runTimeOut: true });
      });
      window.addEventListener("touchstart", event => {
        this.showHeaderFooter({ runTimeOut: true });
      });
      window.addEventListener("keypress", event => {
        this.showHeaderFooter({ runTimeOut: true });
      });
    },

    /**
     * Function to remove event listener for footer toggling
     */
    removeToggleHeaderFooterEventListener() {
      window.removeEventListener("click", event => {
        this.showHeaderFooter({ runTimeOut: false });
      });
      window.removeEventListener("mousemove", event => {
        this.showHeaderFooter({ runTimeOut: false });
      });
      window.removeEventListener("mousedown", event => {
        this.showHeaderFooter({ runTimeOut: false });
      });
      window.removeEventListener("touchstart", event => {
        this.showHeaderFooter({ runTimeOut: false });
      });
      window.removeEventListener("keypress", event => {
        this.showHeaderFooter({ runTimeOut: false });
      });
    },

    /**
     * Callback after fetching input/output devices
     */
    gotDevices(deviceInfos) {
      // Resetting the audio/video input/output array
      this.setAudioOutput([]);
      this.setAudioInput([]);
      this.setVideoInput([]);

      deviceInfos.forEach(deviceInfo => {
        if ("audioinput" === deviceInfo.kind) {
          // Push to audio input array
          this.setAudioInput([
            ...this.audioInput,
            {
              key: deviceInfo.deviceId,
              value:
                deviceInfo.label || `microphone ${this.audioInput.length + 1}`
            }
          ]);

          // Select the first one by default
          if (this.audioTrack.getSettings().deviceId === deviceInfo.deviceId) {
            this.setSelectedAudioInput(deviceInfo.deviceId);
          }
        } else if ("videoinput" === deviceInfo.kind) {
          // Push to video input array
          this.setVideoInput([
            ...this.videoInput,
            {
              key: deviceInfo.deviceId,
              value: deviceInfo.label || `camera ${this.videoInput.length + 1}`
            }
          ]);

          // Select the first one by default
          if (this.videoTrack.getSettings().deviceId === deviceInfo.deviceId) {
            this.setSelectedVideoInput(deviceInfo.deviceId);
          }
        } else if ("audiooutput" === deviceInfo.kind) {
          // Push to audio output array
          this.setAudioOutput([
            ...this.audioOutput,
            {
              key: deviceInfo.deviceId,
              value: deviceInfo.label || `speaker ${this.videoInput.length + 1}`
            }
          ]);

          // Select the first one by default
          if (1 === this.audioOutput.length) {
            this.setSelectedAudioOutput(this.audioOutput[0].key);
          }
        }
      });
    },

    /**
     * Starting the video call by fetching camera/audio stream
     */
    fetchCameraStream() {
      // Listing constraints
      let constraints = {};
      if (this.checkIfMobile) {
        constraints = {
          audio: {
            autoGainControl: true,
            echoCancellation: true,
            noiseSuppression: true
          },
          video: {
            facingMode:
              "front" === this.cameraFacingMode
                ? "user"
                : { exact: "environment" }
          }
        };
      } else {
        constraints = {
          video: true,
          audio: {
            autoGainControl: true,
            echoCancellation: true,
            noiseSuppression: true
          }
        };
      }

      if (navigator.mediaDevices.getUserMedia) {
        // Stop the previous tracks of stream
        if (this.cameraStream) {
          this.cameraStream.getTracks().forEach(track => {
            track.stop();
          });
        }

        // Settings flag which checks, if camera stream ready
        this.setIsCameraStreamReady(false);

        // Fetching the system videos and audios
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            // Set the camera stream on vuex state
            this.setCameraStream(stream);

            // Set the shared track on vuex state
            const tracks = stream.getTracks();
            tracks.forEach(track => {
              // Set the audio track, to be shared with all participants
              if ("audio" === track.kind) {
                this.setAudioTrack(track);
              }

              // Set the video track, to be shared with all participants
              if ("video" === track.kind) {
                this.setVideoTrack(track);
              }
            });

            // Join the user to meet/wait room
            socket.io.emit(
              "joinRoom",
              { room: this.$router.currentRoute.query.room },
              response => {
                if (response.status) {
                  // Set the room(Meet Room/ Wait Room), configuration details on state
                  this.setMeetDetails({
                    ...this.meetDetails,
                    room: response.roomToJoin,
                    enableChat: response.enableChat,
                    screenShare: response.screenShare,
                    enableUnmute: response.enableUnmute,
                    recordStatus: response.recordStatus
                  });

                  // Setting participant list
                  if (response.participantList) {
                    this.setParticipantList(response.participantList);
                  }

                  // Setting guest user list
                  if (response.guestUserList) {
                    this.setGuestUserList([
                      ...this.guestUserList,
                      ...response.guestUserList
                    ]);
                  }

                  // Initiate the connection, if user has to join meet room
                  if ("meetRoom" === response.roomToJoin) {
                    socket.io.emit("newConnection", {
                      socketId: socket.io.id,
                      userName: this.userName,
                      userId: this.userId,
                      isAudioMuted: this.meetDetails.isUserAudioMuted,
                      isVideoMuted: this.meetDetails.isUserVideoMuted,
                      videoTrackType: this.meetDetails.videoTrackType
                    });
                  }

                  // Update socket leaving time periodically
                  this.updateSocketLeaveTimePeriodically();

                  // Setting join id on vuex
                  this.setJoinId(response.meetJoinInfoId);
                } else {
                  this.$store.dispatch("displayGlobalAlert", {
                    type: "error",
                    msg: response.message
                  });
                }
              }
            );

            // Listing devices
            return navigator.mediaDevices.enumerateDevices();
          })
          .then(deviceInfos => this.gotDevices(deviceInfos))
          .catch(this.errorHandler)
          .finally(() => {
            // Settings flag which checks, if camera stream is ready or not
            this.setIsCameraStreamReady(true);
          });

        // Hide footer on mobile devices if there is no activity for 2 mins
        let mql = window.matchMedia("(orientation: landscape)");
        if (mql.matches && window.innerHeight <= 425) {
          this.showHeaderFooter({ runTimeOut: true });
          this.addToggleHeaderFooterEventListener();
        }

        window.addEventListener("resize", () => {
          let mql = window.matchMedia("(orientation: landscape)");
          if (mql.matches && window.innerHeight <= 425) {
            // Check landscape orientation for mobile devices.
            this.showHeaderFooter({ runTimeOut: true });
            this.addToggleHeaderFooterEventListener();
          } else {
            // Portrait orientation
            this.showHeaderFooter({ runTimeOut: false });
            this.removeToggleHeaderFooterEventListener();
          }
        });
      } else {
        this.$store.dispatch("displayGlobalAlert", {
          type: "error",
          msg: GeneralConstant.error.getUserMediaNotSupported
        });
      }
    },

    /**
     * Function to change audio/video input/output devices
     */
    changeCameraFacingMode() {
      // If screen share is on, video track can not be changed.
      if (
        "screen" === this.meetDetails.videoTrackType ||
        this.meetDetails.isUserVideoMuted ||
        !this.isCameraStreamReady
      ) {
        return false;
      }

      // Settings flag which checks, if camera stream is ready or not
      this.setIsCameraStreamReady(false);

      // Stop the previous tracks of stream
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach(track => {
          if ("video" === track.kind) {
            track.stop();
          }
        });
      }

      // Fetching the system videos and audios with changed deviceId. Getting a new stream
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode:
              "front" === this.cameraFacingMode
                ? "user"
                : { exact: "environment" }
          }
        })
        .then(stream => {
          // Setting the camera stream
          this.setCameraStream(stream);

          // Get the track
          let track = stream.getVideoTracks()[0];

          // Set the video track
          this.setVideoTrack(track);

          // Replace track for all rtcRtpSender
          for (const id in this.rtcRtpSenders) {
            let rtcRtpSender = this.rtcRtpSenders[id].find(
              rtcRtpSender => rtcRtpSender.track.kind === "video"
            );
            rtcRtpSender.replaceTrack(track);
          }
        })
        .catch(this.errorHandler)
        .finally(() => {
          // Settings flag which checks, if camera stream ready or not
          this.setIsCameraStreamReady(true);
        });
    },

    /**
     * Error on accessing input/output device gets catchup here
     *
     * @param {Object} error
     */
    errorHandler(error) {
      let errorMsg = error;
      if ("object" === typeof error) {
        errorMsg = error.message;
      }
      this.$store.dispatch("displayGlobalAlert", {
        type: "error",
        msg: errorMsg
      });
    }
  }
};
