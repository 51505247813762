var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'common-container': true,
    'meet-room': 'meetRoom' === _vm.meetDetails.room,
    'full-height': _vm.isHeaderFooterHidden
  },on:{"dblclick":_vm.toggleFullScreen}},[('waitRoom' === _vm.meetDetails.room)?_c('div',{staticClass:"waiting-room"},[_c('overlay-box-vue',{attrs:{"is-chat-pannel-opened":_vm.isChatPannelOpened,"toggle-sidebar":_vm.toggleSidebar}}),_c('div',{ref:"screenContainer",staticClass:"wr-graphic"},[_c('video',{attrs:{"autoplay":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.waitRoomvideoFileName,"type":"video/mp4"}}),_c('img',{attrs:{"src":_vm.waitingRoomImg,"title":"Your browser does not support the <video> tag"}})])])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:('meetRoom' === _vm.meetDetails.room),expression:"'meetRoom' === meetDetails.room"}],class:{ 'room-pinned': 'pinned' === _vm.displayView },attrs:{"id":"video-room"}},[_c('participant-list-vue',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.peerConnections).length > 0),expression:"Object.keys(peerConnections).length > 0"}],attrs:{"toggle-rename-popup":_vm.toggleRenamePopup,"hide-self-view":_vm.hideSelfView}}),_c('video-thumb-vue',{directives:[{name:"show",rawName:"v-show",value:(
        0 === Object.keys(_vm.peerConnections).length ||
          ('speaker' === _vm.displayView && !_vm.meetDetails.hideSelfView) ||
          'pinned' === _vm.displayView
      ),expression:"\n        0 === Object.keys(peerConnections).length ||\n          ('speaker' === displayView && !meetDetails.hideSelfView) ||\n          'pinned' === displayView\n      "}],attrs:{"toggle-rename-popup":_vm.toggleRenamePopup,"hide-self-view":_vm.hideSelfView}})],1),(_vm.hostInfo.id !== _vm.userId && _vm.meetDetails.recordStatus)?_c('span',{staticClass:"record-indicator"},[_vm._v(" Recording in progress ")]):_vm._e(),_c('div',{staticClass:"chat-notification"},[_c('transition-group',{attrs:{"name":"slide","tag":"div"}},_vm._l((_vm.notifications),function(notification){return _c('chat-notification-vue',{key:notification.id,attrs:{"notification":notification,"is-chat-pannel-opened":_vm.isChatPannelOpened,"toggle-sidebar":_vm.toggleSidebar}})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }